<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="text-right" v-if="!userIsNotAuthorised">
        <button
          class="
            button
            page__body__header__button
            ml-auto
            button--grey
            text--capital
          "
          type="button"
          data-toggle="modal"
          data-target="#addReasonModal"
        >
          Add Reason <i class="ki ki-plus icon-sm ml-3"> </i>
        </button>
      </div>
      <label
        class="
          d-block
          has-checkbox
          page__body__header__label
          text--black text--600 text--regular text--capital
          mx-3
          banner
        "
        v-if="!userIsNotAuthorised"
        >Show Deactivated
        <input
          type="checkbox"
          class="form-"
          v-model="showDeactivated"
          @click="switchShowDeactivated()"
        />
        <span class="checkmark"></span>
      </label>

      <div class="mt-5">
        <table class="table customtable customtable__templateid">
          <thead class="table__header">
            <tr class="table_header_text">
              <th style="width: 40%;">Name</th>
              <th style="width: 35%;">Description</th>
              <th style="width: 15%;">Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="userIsNotAuthorised">
            <tr>
              <td colspan="12">
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    p5
                  "
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5"
                    >{{ errorResponse }}</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              class="table__text"
              v-for="item in allReasons"
              v-bind:key="item.reasonKey"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.status }}</td>
              <td>
                <div class="dropdown">
                  <button
                    class="button btn-light dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    Action
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-container"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#editReasonModal"
                      v-on:click="editReason(item)"
                      >Edit</a
                    >
                    <a
                      v-if="item.status == 'Inactive'"
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#switchActivationModal"
                      @click="
                        setSwitchActivationParams(item.reasonKey, 'ACTIVATE')
                      "
                      >activate</a
                    >
                    <a
                      v-if="item.status == 'Active'"
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#switchActivationModal"
                      @click="
                        setSwitchActivationParams(item.reasonKey, 'DEACTIVATE')
                      "
                      >deactivate</a
                    >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#deleteConfirmModal"
                      v-on:click="openConfirmation(item)"
                      >Delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination" v-if="!userIsNotAuthorised">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >
              <span class="d-inline-block text--primary" v-else>{{
                !paging.hasPreviousPage
                  ? paging.pageIndex
                  : paging.pageSize * (paging.pageIndex - 1) + 1
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.hasNextPage
                  ? paging.pageSize * paging.pageIndex
                  : paging.totalCount
              }}</span>
              of
              <span>{{ paging.totalCount }}</span>
            </div>
            <div
              class="
                pagination__buttons
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <button
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <!-- <button
                class="button text--regular button--sm ml-2 button--primary text--white"
              >
                1
              </button>
              <button class="button text--regular button--sm ml-2">2</button>
              <button class="button text--regular button--sm ml-2">3</button> -->
              <button
                class="button text--regular button--sm ml-2"
                v-for="num in paging.totalPages"
                :key="num"
                :class="{
                  'button--primary text--white': paging.pageIndex === num,
                }"
                :disabled="paging.pageIndex === num"
                @click="changePage(num)"
              >
                {{ num }}
              </button>

              <button
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal__right" id="addReasonModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                id="formCloseButton"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Create Reason</h5>
              </div>
            </div>
            <form class="form" @submit="saveForm">
              <div class="form__item">
                <label class="form__label">Name</label>
                <input
                  class="input form__input w-100"
                  v-model="form.name"
                  placeholder="Enter name"
                  v-bind:class="{ 'input-required': formErr.name }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">Status</label>
                <select
                  class="select select--lg"
                  v-model="form.status"
                  v-bind:class="{ 'input-required': formErr.status }"
                >
                  <option :value="null" disabled selected>Select status</option>
                  <option
                    v-for="item in reasonStatuses"
                    v-bind:key="item + 'edit'"
                    v-bind:value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="form__item">
                <label class="form__label">Description</label>
                <textarea
                  v-model="form.description"
                  v-bind:class="{ 'input-required': formErr.description }"
                  cols="5"
                  rows="5"
                  class="input form__input"
                ></textarea>
              </div>
              <div class="form__item">
                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="
                    button
                    form__action__text form__action__text--danger
                    ml-5
                  "
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div class="modal modal__right" id="editReasonModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                id="editFormCloseButton"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Edit Reason</h5>
              </div>
            </div>
            <form class="form" @submit="saveEditForm">
              <div class="form__item">
                <label class="form__label">Name</label>
                <input
                  class="input form__input w-100"
                  v-model="form.name"
                  placeholder="Enter name"
                  v-bind:class="{ 'input-required': formErr.name }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">Status</label>
                <select
                  class="select select--lg"
                  v-model="form.status"
                  v-bind:class="{ 'input-required': formErr.status }"
                >
                  <option :value="null" disabled selected>Select status</option>
                  <option
                    v-for="item in reasonStatuses"
                    v-bind:key="item + 'edit'"
                    v-bind:value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="form__item">
                <label class="form__label">Description</label>
                <textarea
                  v-model="form.description"
                  v-bind:class="{ 'input-required': formErr.description }"
                  cols="5"
                  rows="5"
                  class="input form__input"
                ></textarea>
              </div>
              <div class="form__item">
                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="
                    button
                    form__action__text form__action__text--danger
                    ml-5
                  "
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
    <div
      class="modal modal__confirm"
      id="switchActivationModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Under writing Reason
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to {{ switchActivationDisplay }} this
                Underwriting Reason
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  id="activationCloseButton"
                  data-dismiss="modal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                >
                  cancel
                </button>
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isSaving"
                  @click="switchActivationFunc()"
                  class="
                    button button--lg
                    modal__confirm__button
                    ml-2
                    save-changes
                  "
                >
                  {{ switchActivationDisplay }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <a data-dismiss="modal" class="modal__close modal__close--black">
        <ion-icon
          name="arrow-back-outline"
          data-dismiss="modal"
          id="deleteCloseButton"
          aria-label="Close"
          :disabled="isDeleting"
        ></ion-icon>
      </a>
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content" v-if="reasonToDelete">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this reason, '{{
                    reasonToDelete.name
                  }}' ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this reason from your list , you won't be able
                  to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="
                  button
                  form__button form__button--lg
                  mr-5
                  modal__confirm__button modal__confirm__button--cancel
                "
                data-dismiss="modal"
                aria-label="Close"
                v-if="!isDeleting"
              >
                Cancel
              </button>
              <!-- loader button -->
              <a
                role="button"
                class="
                  button
                  form__button form__button--lg
                  w-25
                  modal__confirm__button modal__confirm__button--delete
                "
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="
                  button
                  form__button form__button--lg
                  modal__confirm__button modal__confirm__button--delete
                "
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteReason()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import underwritingReasonService from "@/core/services/underwriting/underwriting-reason.service";
export default Vue.extend({
  name: "underwriting-reason",
  computed: {
    userIsNotAuthorised() {
      return this.errorCode == 409;
    }
  },
  data: function () {
    return {
      content_header: {
        title: "Underwriting Reasons",
        subTitle: "Set up organization's allowable underwriting reasons.",
      },
      showDeactivated: false,
      reason: null,
      reasonToDelete: null,
      allReasons: [],
      fetchModel: {
        status: "Active",
        pageIndex: 0,
        pageSize: 10,
      },
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      reasonStatuses: ["Active", "Inactive"],
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      form: {
        name: null,
        description: null,
        status: null,
      },
      formErr: {
        name: null,
        description: null,
        status: null,
      },
      errorCode: '',
      errorResponse: '',
      toSwitchActivation: null,
      switchActivationDisplay: null,
    };
  },
  methods: {
    async getReasons() {
      try {
        this.isLoading = true;
        const res = await underwritingReasonService.get(this.fetchModel);
        this.allReasons = res.data.data.items;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data;
        this.errorResponse = e.response.data;
        this.errorCode = e.response.status;
        this.alertError(error);
      }
    },
    editReason(reason) {
      try {
        this.isLoading = true;
        this.reason = reason;
        this.form = {
          reasonKey: reason.reasonKey,
          name: reason.name,
          description: reason.description,
          status: reason.status,
        };
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await underwritingReasonService.create(this.form);
          this.getReasons();
          this.isSaving = false;
          document.getElementById("formCloseButton").click();
          this.clearForm();
          this.alertSuccess("New reason added.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await underwritingReasonService.update(this.form);
          this.getReasons();
          this.isSaving = false;
          document.getElementById("editFormCloseButton").click();
          this.clearForm();
          this.alertSuccess("Reason updated.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    validateForm() {
      this.clearValidation();
      if (this.form.name && this.form.description && this.form.status) {
        return true;
      }

      for (const key in this.formErr) {
        if (Object.hasOwnProperty.call(this.formErr, key)) {
          if (
            this.form[key] == null ||
            this.form[key] == undefined ||
            this.form[key].trim() == ""
          ) {
            this.formErr[key] = true;
          }
        }
      }
      return false;
    },
    clearValidation() {
      this.formErr = {
        name: null,
        description: null,
        status: null,
      };
    },
    clearForm: function () {
      this.form = {
        name: null,
        description: null,
        status: null,
      };
    },
    async deleteReason() {
      this.isDeleting = true;
      try {
        await underwritingReasonService.delete(this.reasonToDelete.reasonKey);
        this.isDeleting = false;
        document.getElementById("deleteCloseButton").click();
        this.alertSuccess("Reason deleted");
        this.getReasons();
      } catch (e) {
        this.isDeleting = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    openConfirmation(reason) {
      this.reasonToDelete = reason;
    },
    switchShowDeactivated() {
      this.showDeactivated = !this.showDeactivated;
      if (this.showDeactivated) {
        this.fetchModel.status = null;
      } else {
        this.fetchModel.status = "Active";
      }
      this.getReasons();
    },
    setSwitchActivationParams(reasonKey, display) {
      this.toSwitchActivation = reasonKey;
      this.switchActivationDisplay = display;
    },
    switchActivationFunc: async function () {
      switch (this.switchActivationDisplay) {
        case "ACTIVATE":
          try {
            this.isSaving = true;
            await underwritingReasonService.activate(this.toSwitchActivation);
            this.getReasons();
            document.getElementById("activationCloseButton").click();
            this.alertSuccess("Activated.");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
          break;
        case "DEACTIVATE":
          try {
            this.isSaving = true;
            await underwritingReasonService.deactivate(this.toSwitchActivation);
            this.getReasons();
            document.getElementById("activationCloseButton").click();
            this.alertSuccess("Deactivated");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
          break;
        default:
          break;
      }
    },
    // Alert
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    // Paging
    changePage(pageNum) {
      this.fetchModel.pageIndex = pageNum;
      this.getReasons();
    },
    stepPage(direction) {
      if (direction == "next") {
        this.fetchModel.pageIndex = this.paging.pageIndex + 1;
      }
      if (direction == "previous") {
        this.fetchModel.pageIndex = this.paging.pageIndex - 1;
      }
      this.getReasons();
    },
    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
  },
  async created() {
    this.getReasons();
  },
});
</script>
